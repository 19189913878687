// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-how-js": () => import("./../src/pages/how.js" /* webpackChunkName: "component---src-pages-how-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-devies-js": () => import("./../src/pages/portfolio/devies.js" /* webpackChunkName: "component---src-pages-portfolio-devies-js" */),
  "component---src-pages-portfolio-full-stack-developer-js": () => import("./../src/pages/portfolio/full-stack-developer.js" /* webpackChunkName: "component---src-pages-portfolio-full-stack-developer-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-school-projects-js": () => import("./../src/pages/portfolio/school-projects.js" /* webpackChunkName: "component---src-pages-portfolio-school-projects-js" */),
  "component---src-pages-portfolio-summer-internship-js": () => import("./../src/pages/portfolio/summer-internship.js" /* webpackChunkName: "component---src-pages-portfolio-summer-internship-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

